import React, { useState, useEffect, useCallback } from 'react';
import useHttp from "../../hooks/use-http";
import { API_TAGS_URL } from "../../constants/API";
import './SuccessStories.scss'; // Assuming you have a SCSS file for styles

const SuccessStories = () => {
  const [stories, setStories] = useState([]);
  const [currentStory, setCurrentStory] = useState(0);

  // Fetch data from the API
  useEffect(() => {
    fetch(`${API_TAGS_URL}/success-stories/`)
      .then((response) => response.json())
      .then((data) => {
        setStories(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  // Function to change the current story
  const handleStoryChange = (index) => {
    setCurrentStory(index);
  };

  return (
    <div className="success-stories">
      <h2>Success Stories</h2>
      <p>Case Studies Showcasing Our Clients' Growth</p>
      

      {stories.length > 0 && (
        <div className="story-container">
          <div className='story-background-r'></div>
          <div className='story-background-l'></div>
          <div className='story-background-l-l'></div>
          <div><img
              src={stories[currentStory].logo}
              className="company-logo"
            /></div>
            
          <div className="story-left">
            <blockquote>
              {stories[currentStory].quote}
            </blockquote>
          </div>

          <div className="story-right">
            
            <p>{stories[currentStory].story}</p>
            <ul >
              {stories[currentStory].benefits_list.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
          </div>

          
        </div>
      )}
      {/* Pagination (radio buttons) */}
      <div className="story-pagination">
            {stories.map((_, index) => (
              <input
                key={index}
                type="radio"
                name="story"
                checked={currentStory === index}
                onChange={() => handleStoryChange(index)}
              />
            ))}
          </div>
    </div>
  );
};

export default SuccessStories;